.listing__header__for {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.listing__candidate-header {
  margin-left: 0.5rem !important;
}

.btn:disabled {
  color: white;
}
