.sign-in__container {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.sign-in__column {
  margin: 0 auto;
}

.campaign__reach {
  text-decoration: underline;
  margin: 0 1ch;
  color: #f2706d;
}

.campaign__favorite-button {
  color: gold !important;
}

.campaign__hidden {
  color: rgba(0, 0, 0, 1) !important;
  opacity: 0.6;
}

.campaign__hidden-title > a {
  color: rgba(0, 0, 0, 1) !important;
  opacity: 0.6;
}

.campaign__unseen-zero {
  color: rgba(0, 0, 0, 1) !important;
  opacity: 0.6 !important;
}

.campaign__counter {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  border-radius: 50%;
  width: 128px;
  height: 128px;
  background-color: #f2706d;
}

.campaign__dropdown-wrapper {
  display: inline-block;
  padding: 0;
}

.campaign__dropdown-toggle-text {
  color: #f2706d;
}

.progress-circle__container {
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.progress-circle__spinner {
  width: 128px !important;
  height: 128px !important;
  margin-top: 100px !important;
}

.listing__header__for {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.listing__candidate-header {
  margin-left: 0.5rem !important;
}

.filter__capital {
  text-transform: capitalize;
}

.filter__expander {
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.5) !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4ab6ad !important;
  color: #fff !important;
}

.sidebar__container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.menu__excel-export-btn {
  background-color: #f1f3f5 !important;
}

.menu__filter-head {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 16px !important;
  line-height: 16px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-right: 0 !important;
}

.listing__header__for {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.listing__candidate-header {
  margin-left: 0.5rem !important;
}

.btn:disabled {
  color: white;
}

.recharts-wrapper {
  margin: 0 auto;
}

.dashboard__container {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.app__container {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.app__content {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  min-height: 0;
}

* {
  box-sizing: border-box;
}

html {
  min-height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  overflow-y: scroll;
  display: -webkit-flex;
  display: flex;
}

body {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: 0;
  box-sizing: border-box;
}

#root,
#root > div {
  width: 100%;
  height: 100%;
}

.container-border {
  border: 1px solid #d8d8d8;
}

html {
  height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  overflow-y: auto;
}

.scroll-y {
  overflow-y: scroll;
}

body {
  font-family: "Montserrat", sans-serif;
  height: 100%;
}

.text-action {
  color: #f2706d;
}

.alert-danger {
  border-color: #f8d7da;
  color: #e36172;
}

.modal-backdrop {
  background-color: #0f003d;
}

.modal-content {
  border: none;
}

.modal-dialog {
  margin-top: 40vh;
}

.topnav a {
  width: 110px;
}

.topnav a.tab-item {
  background-color: #0f003d;
  color: #ffffff;
  border-top: 3px solid #0f003d;
}

.topnav a.tab-item.active {
  background: -webkit-linear-gradient(
    45deg,
    rgb(45, 0, 133) 0%,
    rgb(95, 0, 144) 60%,
    rgb(114, 0, 148) 100%
  );
  color: #ff47bd;
  border-top: 3px solid #ff47bd;
}

.navbar-brand {
  line-height: 2.8rem;
  font-weight: bolder;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.5);
}

.alert-success {
  background: -webkit-linear-gradient(
    45deg,
    rgb(45, 0, 133) 0%,
    rgb(95, 0, 144) 60%,
    rgb(114, 0, 148) 100%
  );
  border: none;
  color: #fff;
}

a.tab-item {
  transition: all 0.2s;
}

a.tab-item:hover {
  color: #ff47bd !important;
}

a.tab-item.active {
  background: -webkit-linear-gradient(
    45deg,
    rgb(45, 0, 133) 0%,
    rgb(95, 0, 144) 60%,
    rgb(114, 0, 148) 100%
  );
  color: #ff47bd;
}

.js-tabby .tabs-pane.active {
  width: 100%;
}

.cards-item {
  transition: all 0.2s;
}

.cards-item:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.cards-link {
  transition: all 0.2s;
}

.cards-link:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  cursor: pointer;
}

.cards-link:active,
.cards-link:focus {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  cursor: pointer;
}

.btn {
  cursor: pointer;
  display: -webkit-inline-flex;
  display: inline-flex;
}

.btn-success {
  background-color: #ff47bd;
  border-color: #ff47bd;
}

.btn-success:hover {
  background-color: #710192;
  border-color: #710192;
}

.btn-secondary {
  color: #fff;
  background-color: #180046;
  border-color: #180046;
}

.btn-secondary:hover {
  background-color: #710192;
  border-color: #710192;
}

.btn-outline-success {
  background-color: transparent;
  border-color: #fb457d;
  color: #fff;
}

.btn-outline-success:hover {
  background-color: #710192;
  border-color: #710192;
  color: #fff;
}

.btn-outline-secondary {
  border-color: #6c757d;
  background: #fff;
}

.btn-outline-secondary:hover {
  border-color: #710192;
  background: #710192;
}

.btn-danger {
  background-color: #e36172;
  border-color: #e36172;
}

.btn-danger-outline {
  background-color: transparent;
  color: #fff;
  border-color: #e36172;
}

ion-icon {
  padding-top: 0.25rem;
}

.btn-fb {
  background-color: #475fa1;
  color: #fff;
}

.btn-li {
  background-color: #2e76b4;
  color: #fff;
}

.btn-primary,
.btn-action {
  border-width: 3px;
  border-color: #180046;
  background-color: #180046;
  color: #fff;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-action:hover,
.btn-action:focus,
.btn-action:active {
  border-color: #710192;
  background-color: #710192;
  color: #fff;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #710192;
  /* border-color: #s; */
  color: "white";
  opacity: 0.8;
}

.btn-light {
  border-width: 3px;
  background-color: #f1f3f5;
  border-color: #f8f9fa;
}

.btn-light:hover,
.btn-light:active,
.btn-light:focus {
  background-color: #eaedf0;
  border-color: #eaedf0;
}

.btn {
  position: relative;
  border-radius: 5rem;
}

.btn:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.btn:disabled {
  color: white;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
}

.btn-md {
  padding: 0.8rem 1rem;
}

label {
  color: #999;
  margin-bottom: 0;
  font-size: 0.8rem;
}

.form-control {
  background-color: inherit;
}

.form-control:focus {
  border-color: #710091;
}

.form-control-lg:disabled {
  background: none;
  color: #bbb;
}

.form-control-lg {
  font-weight: 500;
  border: none;
  border-bottom: 2px solid #ccc;
  color: #666;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.form-control-xl {
  font-size: 1.5rem;
  line-height: 1.5;
}

input.has-error {
  border-color: #e36172;
}

small.has-error {
  color: #e36172;
  font-weight: 600;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: rgb(255, 168, 69) !important;
}

.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #4ab6ad;
}

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #4ab6ad;
}

.custom-control-input:active ~ .custom-control-indicator {
  color: #fff;
  background-color: #60b8cf;
}

.text-gold {
  color: #f7cb1a;
}

.text-white {
  color: #fff;
}

a {
  color: #4ab6ad;
}

a:hover {
  color: #e6756e;
  text-decoration: none;
}

.mt-6 {
  margin-top: 4rem;
}

.mt-10 {
  margin-top: 6rem;
}

.p-6 {
  padding: 4rem;
}

.hv-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.text-adway {
  color: #4ab6ad;
}

.text-adway-primary {
  color: #e03f6f;
}

.text-op-75 {
  opacity: 0.75;
}

.shdw {
  box-shadow: 0px 0px 40px -12px rgba(0, 0, 0, 0.64);
}

.border-progress {
  border: solid 6px #f8f9fa;
  box-sizing: border-box;
}

.ct-series.car {
  fill: #720094;
}
.ct-series.foo {
  fill: #fa446b;
}
.ct-series.bar {
  fill: #ff47bd;
}

.iphone2 {
  background: url(/images/iphone-mockup.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
  max-width: 301px;
  height: 199%;
  display: block;
}

.iphone-top {
  background: url(/images/backgrounds/top.png);
  background-repeat: no-repeat;
  background-position: top center;
  height: 75px;
  overflow: hidden;
}

.iphone-body {
  background: url(/images/backgrounds/body.png);
  background-repeat: repeat-y;
  background-position: center center;
  height: 500px;
  overflow: hidden;
  overflow-y: hidden;
}

.iphone-body .iphone-wrap {
  font-size: 11px;
  color: #333;
  text-align: left;
  width: 288px;
  margin: 0 auto;
  line-height: 1.5;
  height: 500px;
  overflow: hidden;
  overflow-y: auto;
}

.iphone-body .iphone-wrap .btn-sm {
  font-size: 8px;
}

.iphone-bottom-facebook {
  background: url(/images/backgrounds/facebook-bottom.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  height: 106px;
  overflow: hidden;
}
.iphone-bottom-instagram {
  background: url(/images/backgrounds/instagram-bottom.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  height: 106px;
  overflow: hidden;
}

.iphone-bottom-linkedin {
  background: url(/images/backgrounds/linkedin-bottom.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  height: 106px;
  overflow: hidden;
}

.bg-muted {
  background-color: #f5f5f5;
}

.bg-active-b-0 {
  background: none;
  background-color: #3b0047;
  border: none;
}

.bg-active {
  background: none;
  background-color: #8d365b;
  border-left: 5px #e36172 solid;
  color: #ffe28b;
}

.h-adway {
  background: -webkit-linear-gradient(360deg, #b969ff, #fe47be);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.bg-adway0 {
  background-color: #0f003d;
}
.bg-adway1 {
  background-color: #281b4e;
}

.bg-adway {
  background-color: #2d0085;
  background: -webkit-linear-gradient(
    45deg,
    #2d0085 0%,
    rgb(95, 0, 144) 60%,
    rgb(114, 0, 148) 100%
  );
}

.bg-adway2 {
  background-color: #fa446b;
  background: -webkit-linear-gradient(
    45deg,
    #fa446b 0%,
    #fd469e 60%,
    #ff47bd 100%
  );
}

.bg-adway3 {
  background-color: #0f003d;
  background: linear-gradient(-180deg, #0f003d 0%, #730096 100%);
}

.bg-hexagon {
  background: url(/images/bg-hexagon.svg) no-repeat top center / 100% 100%;
}

.bg-adway-dark {
  background: rgb(59, 0, 71);
}

.bg-adway-primary {
  background: linear-gradient(141deg, #e36172 0%, #bc2e5d 100%);
}

.bg-adway-fade {
  background: linear-gradient(141deg, #f9f9f9 0%, #f3f0f0 100%);
}

.bg-faded {
  background-color: #f9f9f9;
}

.bg-action {
  background: #e03f6f;
}

.bg-facebook {
  background: #3b5998;
}

.bg-linkedin {
  background: #0077b5;
}

.bg-success {
  background-color: #ff47bd !important;
}

.text-success {
  color: #ff47bd !important;
}

.badge-secondary {
  background-color: #180046;
}

.badge-success {
  background-color: #ff47bd;
}

.badge-light {
  background-color: #f5f5f5;
}

.badge-sticker {
  position: absolute;
  bottom: 5px;
  right: -0px;
  padding: 0.25rem 0.3rem;
}

.badge-sticker-lg {
  position: absolute;
  bottom: 10px;
  margin-left: -35px;
  padding: 0.5rem 0.6rem;
}

.badge-pill-lg {
  font-size: 1rem;
  padding: 1.2rem 0.9rem;
}

.relative {
  position: relative;
}

.wc-2 {
  width: 2rem;
  text-align: center;
}

.wc-3 {
  width: 3.3rem;
  text-align: center;
}

hr {
  border-top: 4px solid #493d71;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 15%;
}

.sidebar {
  width: 80px;
  background: #0f003d;
}

.wmax-600 {
  max-width: 600px;
}

.spinner {
    display: block;
    visibility: visible;
    opacity: 1;
    margin: 0 auto;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    border: 3px solid #180046;
    border-top-color: #9f21c5;
    -webkit-animation: spinner 1s ease-in-out infinite;
            animation: spinner 1s ease-in-out infinite;
    transition: border-top-color 2s ease;
    transition: border-color 2s ease;
    margin: 0.22rem auto;
}

.spinner-muted {
    border: 3px solid #f5f5f5;
    border-top-color: #f2706d;
}

.spinner-lg {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    border-width: 5px;
}


@-webkit-keyframes spinner {
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}


@keyframes spinner {
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}




.animated {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}

    .animated.tease {
        -webkit-animation-duration: 0.5s;
        animation-duration: 0.5s;
    }

    .animated.hinge {
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
    }

@-webkit-keyframes bounceIn {

    0% {
        opacity: 0;
        -webkit-transform: scale(0.8);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.05);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
    }
}

@keyframes bounceIn {

    0% {
        opacity: 0;
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.bounceIn {
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.delay {
    -webkit-animation-delay: 0.30s;
    animation-delay: 0.30s;
}

.delay-one {
    -webkit-animation-delay: 0.60s;
    animation-delay: 0.60s;
}

.delay-two {
    -webkit-animation-delay: 1.0s;
    animation-delay: 1.0s;
}

.delay-three {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
                transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
                transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}



@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
                transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}






.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutLeft {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
    }
}

@keyframes fadeOutLeft {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
                transform: translateX(-20px);
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.animated.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

.animated.fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft;
}








.pulse {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    margin-left: -36px;
    width: 22px;
    height: 22px;
    line-height: 72px;
    font-family: sans-serif;
    font-weight: 700;
    font-size: 34px;
    text-align: center;
    color: #fff;
}

    .pulse span,
    .pulse:before,
    .pulse:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 0 solid #65c0de;
        border-radius: 100%;
        -webkit-animation: da-pulse 2s ease-in-out 0s infinite;
        animation: da-pulse 2s ease-in-out 0s infinite;
    }

    .pulse span {
        border-color: #65c0de;
        border-width: 2px;
        top: 6px;
        left: 6px;
        right: 6px;
        bottom: 6px;
    }

    .pulse:before {
        border-color: #99ddf3;
        border-width: 2px;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
    }

    .pulse:after {
        border-color: #a8e5f9;
        border-width: 1px;
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
    }

@-webkit-keyframes da-pulse {
    0%,
    100% {
        -webkit-transform: scale(1.25);
        transform: scale(1.25);
        opacity: 0;
    }
    48%,
    52% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes da-pulse {
    0%,
    100% {
        -webkit-transform: scale(1.25);
        transform: scale(1.25);
        opacity: 0;
    }
    48%,
    52% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}




.wave {
    -webkit-animation: moveTheWave 4800ms linear infinite;
    animation: moveTheWave 4800ms linear infinite;
    stroke-dasharray: 0 16 101 16;
}

@-webkit-keyframes moveTheWave {

    0% {
        stroke-dashoffset: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    100% {
        stroke-dashoffset: -133;
        -webkit-transform: translate3d(-90px, 0, 0);
        transform: translate3d(-90px, 0, 0);
    }

}

@keyframes moveTheWave {

    0% {
        stroke-dashoffset: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    100% {
        stroke-dashoffset: -133;
        -webkit-transform: translate3d(-90px, 0, 0);
        transform: translate3d(-90px, 0, 0);
    }

}


.set-size {
    font-size: 10em;
}
.charts-container:after {
    clear: both;
    content: "";
    display: table;
}
.pie-wrapper {
    height: 1em;
    width: 1em;
    float: left;
    margin: 15px;
    position: relative;
}
.pie-wrapper:nth-child(3n+1) {
    clear: both;
}
.pie-wrapper .pie {
    height: 100%;
    width: 100%;
    clip: rect(0, 1em, 1em, 0.5em);
    left: 0;
    position: absolute;
    top: 0;
}
.pie-wrapper .pie .half-circle {
    height: 100%;
    width: 100%;
    border: 0.1em solid #3498db;
    border-radius: 50%;
    clip: rect(0, 0.5em, 1em, 0);
    left: 0;
    position: absolute;
    top: 0;
}
.pie-wrapper .label {
    background: #34495e;
    border-radius: 50%;
    bottom: 0.4em;
    color: #ecf0f1;
    cursor: default;
    display: block;
    font-size: 0.25em;
    left: 0.4em;
    line-height: 2.6em;
    position: absolute;
    right: 0.4em;
    text-align: center;
    top: 0.4em;
}
.pie-wrapper .label .smaller {
    color: #bdc3c7;
    font-size: .45em;
    padding-bottom: 20px;
    vertical-align: super;
}
    .pie-wrapper .shadow {
        height: 100%;
        width: 100%;
        border: 0.1em solid #f8f9fa;
        border-radius: 50%;
    }
    .pie-wrapper.style-2 .label {
        background: none;
        color: #f8f9fa;
    }
        .pie-wrapper.style-2 .label .smaller {
            color: #f8f9fa;
        }
.pie-wrapper.progress-30 .pie .right-side {
    display: none;
}
.pie-wrapper.progress-30 .pie .half-circle {
    border-color: #3498db;
}
.pie-wrapper.progress-30 .pie .left-side {
    -webkit-transform: rotate(108deg);
    transform: rotate(108deg);
}
.pie-wrapper.progress-60 .pie {
    clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-60 .pie .right-side {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.pie-wrapper.progress-60 .pie .half-circle {
    border-color: #9b59b6;
}
.pie-wrapper.progress-60 .pie .left-side {
    -webkit-transform: rotate(216deg);
    transform: rotate(216deg);
}
.pie-wrapper.progress-90 .pie {
    clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-90 .pie .right-side {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.pie-wrapper.progress-90 .pie .half-circle {
    border-color: #e67e22;
}
.pie-wrapper.progress-90 .pie .left-side {
    -webkit-transform: rotate(324deg);
    transform: rotate(324deg);
}
.pie-wrapper.progress-45 .pie .right-side {
    display: none;
}
.pie-wrapper.progress-45 .pie .half-circle {
    border-color: linear-gradient(141deg, #4ab6ad 0%, #60b8cf 100%);
}
.pie-wrapper.progress-45 .pie .left-side {
    -webkit-transform: rotate(162deg);
    transform: rotate(162deg);
}
.pie-wrapper.progress-75 .pie {
    clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-75 .pie .right-side {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.pie-wrapper.progress-75 .pie .half-circle {
    border-color: #8e44ad;
}
.pie-wrapper.progress-75 .pie .left-side {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}
.pie-wrapper.progress-95 .pie {
    clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-95 .pie .right-side {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
    .pie-wrapper.progress-95 .pie .half-circle {
        border-color: #60b8cf;
    }
.pie-wrapper.progress-95 .pie .left-side {
    -webkit-transform: rotate(342deg);
    transform: rotate(342deg);
}
.pie-wrapper--solid {
    border-radius: 50%;
    overflow: hidden;
}
.pie-wrapper--solid:before {
    border-radius: 0 100% 100% 0%;
    content: '';
    display: block;
    height: 100%;
    margin-left: 50%;
    -webkit-transform-origin: left;
    transform-origin: left;
}
.pie-wrapper--solid .label {
    background: transparent;
}
.pie-wrapper--solid.progress-65 {
    background: linear-gradient(to right, #e67e22 50%, #34495e 50%);
}
.pie-wrapper--solid.progress-65:before {
    background: #e67e22;
    -webkit-transform: rotate(126deg);
    transform: rotate(126deg);
}
.pie-wrapper--solid.progress-25 {
    background: linear-gradient(to right, #9b59b6 50%, #34495e 50%);
}
.pie-wrapper--solid.progress-25:before {
    background: #34495e;
    -webkit-transform: rotate(-270deg);
    transform: rotate(-270deg);
}
.pie-wrapper--solid.progress-88 {
    background: linear-gradient(to right, #3498db 50%, #34495e 50%);
}
.pie-wrapper--solid.progress-88:before {
    background: #3498db;
    -webkit-transform: rotate(43.2deg);
    transform: rotate(43.2deg);
}

