.campaign__reach {
  text-decoration: underline;
  margin: 0 1ch;
  color: #f2706d;
}

.campaign__favorite-button {
  color: gold !important;
}

.campaign__hidden {
  color: rgba(0, 0, 0, 1) !important;
  opacity: 0.6;
}

.campaign__hidden-title > a {
  color: rgba(0, 0, 0, 1) !important;
  opacity: 0.6;
}

.campaign__unseen-zero {
  color: rgba(0, 0, 0, 1) !important;
  opacity: 0.6 !important;
}

.campaign__counter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  width: 128px;
  height: 128px;
  background-color: #f2706d;
}

.campaign__dropdown-wrapper {
  display: inline-block;
  padding: 0;
}

.campaign__dropdown-toggle-text {
  color: #f2706d;
}
