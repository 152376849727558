html {
  height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  overflow-y: auto;
}

.scroll-y {
  overflow-y: scroll;
}

body {
  font-family: "Montserrat", sans-serif;
  height: 100%;
}

.text-action {
  color: #f2706d;
}

.alert-danger {
  border-color: #f8d7da;
  color: #e36172;
}

.modal-backdrop {
  background-color: #0f003d;
}

.modal-content {
  border: none;
}

.modal-dialog {
  margin-top: 40vh;
}

.topnav a {
  width: 110px;
}

.topnav a.tab-item {
  background-color: #0f003d;
  color: #ffffff;
  border-top: 3px solid #0f003d;
}

.topnav a.tab-item.active {
  background: -webkit-linear-gradient(
    45deg,
    rgb(45, 0, 133) 0%,
    rgb(95, 0, 144) 60%,
    rgb(114, 0, 148) 100%
  );
  color: #ff47bd;
  border-top: 3px solid #ff47bd;
}

.navbar-brand {
  line-height: 2.8rem;
  font-weight: bolder;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.5);
}

.alert-success {
  background: -webkit-linear-gradient(
    45deg,
    rgb(45, 0, 133) 0%,
    rgb(95, 0, 144) 60%,
    rgb(114, 0, 148) 100%
  );
  border: none;
  color: #fff;
}

a.tab-item {
  transition: all 0.2s;
}

a.tab-item:hover {
  color: #ff47bd !important;
}

a.tab-item.active {
  background: -webkit-linear-gradient(
    45deg,
    rgb(45, 0, 133) 0%,
    rgb(95, 0, 144) 60%,
    rgb(114, 0, 148) 100%
  );
  color: #ff47bd;
}

.js-tabby .tabs-pane.active {
  width: 100%;
}

.cards-item {
  transition: all 0.2s;
}

.cards-item:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.cards-link {
  transition: all 0.2s;
}

.cards-link:hover {
  transform: translateY(-5px);
  cursor: pointer;
}

.cards-link:active,
.cards-link:focus {
  transform: translateY(0px);
  cursor: pointer;
}

.btn {
  cursor: pointer;
  display: inline-flex;
}

.btn-success {
  background-color: #ff47bd;
  border-color: #ff47bd;
}

.btn-success:hover {
  background-color: #710192;
  border-color: #710192;
}

.btn-secondary {
  color: #fff;
  background-color: #180046;
  border-color: #180046;
}

.btn-secondary:hover {
  background-color: #710192;
  border-color: #710192;
}

.btn-outline-success {
  background-color: transparent;
  border-color: #fb457d;
  color: #fff;
}

.btn-outline-success:hover {
  background-color: #710192;
  border-color: #710192;
  color: #fff;
}

.btn-outline-secondary {
  border-color: #6c757d;
  background: #fff;
}

.btn-outline-secondary:hover {
  border-color: #710192;
  background: #710192;
}

.btn-danger {
  background-color: #e36172;
  border-color: #e36172;
}

.btn-danger-outline {
  background-color: transparent;
  color: #fff;
  border-color: #e36172;
}

ion-icon {
  padding-top: 0.25rem;
}

.btn-fb {
  background-color: #475fa1;
  color: #fff;
}

.btn-li {
  background-color: #2e76b4;
  color: #fff;
}

.btn-primary,
.btn-action {
  border-width: 3px;
  border-color: #180046;
  background-color: #180046;
  color: #fff;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-action:hover,
.btn-action:focus,
.btn-action:active {
  border-color: #710192;
  background-color: #710192;
  color: #fff;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #710192;
  /* border-color: #s; */
  color: "white";
  opacity: 0.8;
}

.btn-light {
  border-width: 3px;
  background-color: #f1f3f5;
  border-color: #f8f9fa;
}

.btn-light:hover,
.btn-light:active,
.btn-light:focus {
  background-color: #eaedf0;
  border-color: #eaedf0;
}

.btn {
  position: relative;
  border-radius: 5rem;
}

.btn:active {
  transform: scale(0.95);
}

.btn:disabled {
  color: white;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
}

.btn-md {
  padding: 0.8rem 1rem;
}

label {
  color: #999;
  margin-bottom: 0;
  font-size: 0.8rem;
}

.form-control {
  background-color: inherit;
}

.form-control:focus {
  border-color: #710091;
}

.form-control-lg:disabled {
  background: none;
  color: #bbb;
}

.form-control-lg {
  font-weight: 500;
  border: none;
  border-bottom: 2px solid #ccc;
  color: #666;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.form-control-xl {
  font-size: 1.5rem;
  line-height: 1.5;
}

input.has-error {
  border-color: #e36172;
}

small.has-error {
  color: #e36172;
  font-weight: 600;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: rgb(255, 168, 69) !important;
}

.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #4ab6ad;
}

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #4ab6ad;
}

.custom-control-input:active ~ .custom-control-indicator {
  color: #fff;
  background-color: #60b8cf;
}

.text-gold {
  color: #f7cb1a;
}

.text-white {
  color: #fff;
}

a {
  color: #4ab6ad;
}

a:hover {
  color: #e6756e;
  text-decoration: none;
}

.mt-6 {
  margin-top: 4rem;
}

.mt-10 {
  margin-top: 6rem;
}

.p-6 {
  padding: 4rem;
}

.hv-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-adway {
  color: #4ab6ad;
}

.text-adway-primary {
  color: #e03f6f;
}

.text-op-75 {
  opacity: 0.75;
}

.shdw {
  -webkit-box-shadow: 0px 0px 40px -12px rgba(0, 0, 0, 0.64);
  -moz-box-shadow: 0px 0px 40px -12px rgba(0, 0, 0, 0.64);
  box-shadow: 0px 0px 40px -12px rgba(0, 0, 0, 0.64);
}

.border-progress {
  border: solid 6px #f8f9fa;
  box-sizing: border-box;
}

.ct-series.car {
  fill: #720094;
}
.ct-series.foo {
  fill: #fa446b;
}
.ct-series.bar {
  fill: #ff47bd;
}

.iphone2 {
  background: url(/images/iphone-mockup.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
  max-width: 301px;
  height: 199%;
  display: block;
}

.iphone-top {
  background: url(/images/backgrounds/top.png);
  background-repeat: no-repeat;
  background-position: top center;
  height: 75px;
  overflow: hidden;
}

.iphone-body {
  background: url(/images/backgrounds/body.png);
  background-repeat: repeat-y;
  background-position: center center;
  height: 500px;
  overflow: hidden;
  overflow-y: hidden;
}

.iphone-body .iphone-wrap {
  font-size: 11px;
  color: #333;
  text-align: left;
  width: 288px;
  margin: 0 auto;
  line-height: 1.5;
  height: 500px;
  overflow: hidden;
  overflow-y: auto;
}

.iphone-body .iphone-wrap .btn-sm {
  font-size: 8px;
}

.iphone-bottom-facebook {
  background: url(/images/backgrounds/facebook-bottom.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  height: 106px;
  overflow: hidden;
}
.iphone-bottom-instagram {
  background: url(/images/backgrounds/instagram-bottom.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  height: 106px;
  overflow: hidden;
}

.iphone-bottom-linkedin {
  background: url(/images/backgrounds/linkedin-bottom.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  height: 106px;
  overflow: hidden;
}

.bg-muted {
  background-color: #f5f5f5;
}

.bg-active-b-0 {
  background: none;
  background-color: #3b0047;
  border: none;
}

.bg-active {
  background: none;
  background-color: #8d365b;
  border-left: 5px #e36172 solid;
  color: #ffe28b;
}

.h-adway {
  background: -webkit-linear-gradient(360deg, #b969ff, #fe47be);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.bg-adway0 {
  background-color: #0f003d;
}
.bg-adway1 {
  background-color: #281b4e;
}

.bg-adway {
  background-color: #2d0085;
  background: -webkit-linear-gradient(
    45deg,
    #2d0085 0%,
    rgb(95, 0, 144) 60%,
    rgb(114, 0, 148) 100%
  );
}

.bg-adway2 {
  background-color: #fa446b;
  background: -webkit-linear-gradient(
    45deg,
    #fa446b 0%,
    #fd469e 60%,
    #ff47bd 100%
  );
}

.bg-adway3 {
  background-color: #0f003d;
  background: linear-gradient(-180deg, #0f003d 0%, #730096 100%);
}

.bg-hexagon {
  background: url(/images/bg-hexagon.svg) no-repeat top center / 100% 100%;
}

.bg-adway-dark {
  background: rgb(59, 0, 71);
}

.bg-adway-primary {
  background: linear-gradient(141deg, #e36172 0%, #bc2e5d 100%);
}

.bg-adway-fade {
  background: linear-gradient(141deg, #f9f9f9 0%, #f3f0f0 100%);
}

.bg-faded {
  background-color: #f9f9f9;
}

.bg-action {
  background: #e03f6f;
}

.bg-facebook {
  background: #3b5998;
}

.bg-linkedin {
  background: #0077b5;
}

.bg-success {
  background-color: #ff47bd !important;
}

.text-success {
  color: #ff47bd !important;
}

.badge-secondary {
  background-color: #180046;
}

.badge-success {
  background-color: #ff47bd;
}

.badge-light {
  background-color: #f5f5f5;
}

.badge-sticker {
  position: absolute;
  bottom: 5px;
  right: -0px;
  padding: 0.25rem 0.3rem;
}

.badge-sticker-lg {
  position: absolute;
  bottom: 10px;
  margin-left: -35px;
  padding: 0.5rem 0.6rem;
}

.badge-pill-lg {
  font-size: 1rem;
  padding: 1.2rem 0.9rem;
}

.relative {
  position: relative;
}

.wc-2 {
  width: 2rem;
  text-align: center;
}

.wc-3 {
  width: 3.3rem;
  text-align: center;
}

hr {
  border-top: 4px solid #493d71;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 15%;
}

.sidebar {
  width: 80px;
  background: #0f003d;
}

.wmax-600 {
  max-width: 600px;
}
